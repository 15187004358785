import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c('div',{staticClass:"pa-4 d-flex align-center",staticStyle:{"background-color":"#F5F5F5"}},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" Fermer ")])],1),_c('div',{staticClass:"pa-6"},[_c(VCardTitle,{staticClass:"mb-6"},[_c('img',{attrs:{"src":"/static/images/picto-dsfr/success.svg","width":"50px"}}),_c('h1',{staticClass:"fr-h5 mb-0 ml-2"},[_vm._v(" Le fichier a été importé avec succès ")])]),_c(VCardText,{staticClass:"text-left"},[_c(VRow,{staticClass:"mb-4 ml-0 mr-0"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.description))]),_c('p',[_vm._v(" Depuis votre tableau de bord, vous devez télédéclarer vos données, lorsque la campagne de télédéclaration est ouverte. ")])]),_c(VRow,[_c(VSpacer),_c('router-link',{attrs:{"to":{ name: 'ManagementPage' }}},[_c(VBtn,{staticClass:"px-4",attrs:{"color":"primary"}},[_vm._v(" Aller sur mon tableau de bord ")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }