import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500"},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),_c(VCard,{staticClass:"text-left"},[_c(VCardTitle,[_c('h1',{staticClass:"fr-h5 mb-2"},[_vm._v("Voulez-vous corriger votre télédéclaration pour l'année "+_vm._s(_vm.diagnostic.year)+" ?")])]),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(" L’action de correction annule la télédéclaration déjà en place pour vous permettre de modifier les valeurs de votre choix. Les informations sont pré-remplis avec vos dernières données. Une fois corrigé, vous devez soumettre de nouveau votre télédéclaration conformément à l'arrêté du 14 septembre 2022. ")])]),_c(VDivider,{attrs:{"aria-hidden":"true","role":"presentation"}}),_c(VCardActions,{staticClass:"pa-4"},[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Revenir en arrière ")]),_c(VBtn,{attrs:{"outlined":"","color":"red darken-2","text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Annuler et corriger ma télédéclaration ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }